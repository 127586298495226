import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, Image, P } from '../../../AbstractElements';

import { Bounce, ToastContainer, toast } from 'react-toastify';
import { DefaultPage } from '../../../Constants/Misc';
import axios from '../../../Utils/axios';
import { LoginApi } from '../../../api';
import logoWhite from '../../../assets/images/logo/logo.png';
import logoDark from '../../../assets/images/logo/logo_dark.png';
import { CreateAccount, EmailAddress, ForgotPassword, Password, RememberPassword, SignIn } from '../../../constants';

const LoginForm = ({ logoClassMain }) => {

  const [email, setEmail] = useState('test@gmail.com');
  const [password, setPassword] = useState('test123');
  const [remember_me, setRememberMe] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();

  const loginAuth = async (e) => {
    e.preventDefault();

    const formData = {
      email,
      password,
      remember_me
    };

    try {
      const loginResponse = await axios.post(LoginApi, formData, undefined, false);

      if (loginResponse.status === 200) {
        const data = loginResponse.data;
        localStorage.setItem('access_token', data['access_token']);
        localStorage.setItem('refresh_token', data['refresh_token']);
        history(DefaultPage);
      }

    } catch (error) {
      toast.error(error.response.data.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      // Handle login errors (e.g., display error message)
    }
  };

  return (
    <Fragment>
      <div className='login-card'>
        <div>
          <div>
            <Link className={`logo ${logoClassMain}`} to={process.env.PUBLIC_URL}>
              <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
              <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
            </Link>
          </div>

          <div className='login-main'>
            <Form className='theme-form login-form'>
              <H4>Sign in to account</H4>
              <P>Enter your email & password to login</P>
              <FormGroup>
                <Label className='col-form-label m-0'>{EmailAddress}</Label>
                <Input className='form-control' type='email' onChange={(e) => setEmail(e.target.value)} value={email} />
              </FormGroup>
              <FormGroup className='position-relative'>
                <Label className='col-form-label m-0'>{Password}</Label>
                <div className='position-relative'>
                  <Input className='form-control' type={togglePassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} value={password} />
                  <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                    <span className={togglePassword ? '' : 'show'}></span>
                  </div>
                </div>
              </FormGroup>
              <FormGroup className='position-relative'>
                <div className='checkbox'>
                  <Input id='checkbox1' type='checkbox' value={remember_me} onChange={(e) => setRememberMe(e.target.value)} />
                  <Label className='text-muted' for='checkbox1'>
                    {RememberPassword}
                  </Label>
                </div>
                <Link className='link' to={`${process.env.PUBLIC_URL}/auth/forget-pwd`}>
                  {ForgotPassword}
                </Link>
              </FormGroup>
              <FormGroup>
                <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => loginAuth(e) }}>{SignIn}</Btn>
              </FormGroup>
              <P attrPara={{ className: 'text-center mb-0 ' }}>
                Don't have account?
                <Link className='ms-2' to={`${process.env.PUBLIC_URL}/auth/register`}>
                  {CreateAccount}
                </Link>
              </P>
            </Form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default LoginForm;
