import { Lock, Unlock } from "react-feather";

export const ConnectorStatusAvailable = "Available";
export const ConnectorStatusCharging = "Charging";

export const ConnectorStateLocked = "locked";
export const ConnectorStateUnlocked = "unlocked";

export const ConnStatusToBgClassMap = {
  [ConnectorStatusAvailable]: "font-success",
  [ConnectorStatusCharging]: "font-dark",
};

export const ConnStateToIconMap = {
  [ConnectorStateLocked]: <Lock />,
  [ConnectorStateUnlocked]: <Unlock />,
};
