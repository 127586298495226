import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, Image, P } from '../../../AbstractElements';
import { LoginPage } from '../../../Constants/Misc';
import axios from '../../../Utils/axios';
import { RegisterApi } from '../../../api';
import logoWhite from '../../../assets/images/logo/logo.png';
import logoDark from '../../../assets/images/logo/logo_dark.png';

const RegisterFrom = ({ logoClassMain }) => {
  const [togglePassword, setTogglePassword] = useState(false);

  const [form, setForm] = useState({
    name: 'Nijat m',
    phone: '122442',
    email: '12141@gmail.com',
    password: 'test1234',
    voen: '125252'
  });

  const history = useNavigate();

  const onClickSubmitButton = async (e) => {
    e.preventDefault();
    console.log({
      user: {
        name: form.name,
        phone: form.phone,
        email: form.email,
        password: form.password
      },
      operator: {
        prefix: form.name.toLowerCase(),
        ein: form.voen,
      }
    });
    try {
      const loginResponse = await axios.post(RegisterApi, {
        user: {
          name: form.name,
          phone: form.phone,
          email: form.email,
          password: form.password
        },
        operator: {
          prefix: form.name.toLowerCase(),
          ein: form.voen,
        }
      }, undefined, false);

      if (loginResponse.status === 200) {
        history(LoginPage);
      }

    } catch (error) {
      console.log(error.response.data);
      toast.error(error.response.data.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      // Handle login errors (e.g., display error message)
    }

  };
  return (
    <Fragment>
      <div className='login-card'>
        <div>
          <div>
            <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
              <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
              <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
            </Link>
          </div>
          <div className='login-main'>
            <Form className='theme-form login-form'>
              <H4>Create your account</H4>
              <P>Enter your personal details to create account</P>
              <FormGroup>
                <Label className='col-form-label m-0 pt-0'>Your Name</Label>
                <Row className='g-2'>
                  <Col xs='12'>
                    <Input className='form-control' type='text' value={form.name} required='' onChange={(e) => setForm((prevForm) => {
                      return {
                        ...prevForm,
                        name: e.target.value
                      }
                    })} placeholder='Fist Name' />
                  </Col>

                </Row>

              </FormGroup>
              <FormGroup>
                <Label className='col-form-label m-0 pt-0'>VOEN</Label>
                <Row className='g-2'>
                  <Col xs='12'>
                    <Input className='form-control' type='number' value={form.voen} required='' onChange={(e) => setForm((prevForm) => {
                      return {
                        ...prevForm,
                        voen: e.target.value
                      }
                    })} placeholder='VOEN' />
                  </Col>

                </Row>

              </FormGroup>
              <FormGroup>
                <Label className='col-form-label m-0 pt-0'>Phone</Label>
                <Row className='g-2'>
                  <Col xs='12'>
                    <Input className='form-control' type='tel' required='' value={form.phone} onChange={(e) => setForm((prevForm) => {
                      return {
                        ...prevForm,
                        phone: e.target.value
                      }
                    })} placeholder='Phone number' />
                  </Col>

                </Row>

              </FormGroup>
              <FormGroup>
                <Label className='col-form-label m-0 pt-0'>Email Address</Label>
                <Input className='form-control' type='email' value={form.email} onChange={(e) => setForm((prevForm) => {
                  return {
                    ...prevForm,
                    email: e.target.value
                  }
                })} required='' placeholder='Test@gmail.com' />
              </FormGroup>
              <FormGroup className='position-relative'>
                <Label className='col-form-label m-0 pt-0'>Password</Label>
                <div className='position-relative'>
                  <Input className='form-control' value={form.password} onChange={(e) => setForm((prevForm) => {
                    return {
                      ...prevForm,
                      password: e.target.value
                    }
                  })} type={togglePassword ? 'text' : 'password'} name='login[password]' required placeholder='*********' />
                  <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                    <span className={togglePassword ? '' : 'show'}></span>
                  </div>
                </div>
              </FormGroup>
              <FormGroup className='m-0'>
                <div className='checkbox'>
                  <Input id='checkbox1' type='checkbox' />
                  <Label className='text-muted' for='checkbox1'>
                    Agree with <span>Privacy Policy</span>
                  </Label>
                </div>
              </FormGroup>
              <FormGroup>
                <Btn attrBtn={{ className: 'd-block w-100', color: 'primary', type: 'submit', onClick: (e) => onClickSubmitButton(e) }}  >Create Account</Btn>
              </FormGroup>
              {/* <div className='login-social-title'>
                <H6 attrH6={{ className: 'text-muted or mt-4' }}>Or Sign up with</H6>
              </div>
              <div className='social my-4 '>
                <div className='btn-showcase'>
                  <a className='btn btn-light' href='https://www.linkedin.com/login' rel='noreferrer' target='_blank'>
                    <Linkedin className='txt-linkedin' /> LinkedIn
                  </a>
                  <a className='btn btn-light' href='https://twitter.com/login?lang=en' rel='noreferrer' target='_blank'>
                    <Twitter className='txt-twitter' />
                    twitter
                  </a>
                  <a className='btn btn-light' href='https://www.facebook.com/' rel='noreferrer' target='_blank'>
                    <Facebook className='txt-fb' />
                    facebook
                  </a>
                </div>
              </div> */}
              <P attrPara={{ className: 'mb-0 text-start' }}>
                Already have an account?
                <Link className='ms-2' to={`${process.env.PUBLIC_URL}/auth/login`}>
                  Sign in
                </Link>
              </P>
            </Form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default RegisterFrom;
