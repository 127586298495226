import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { Container } from 'reactstrap';

const SettingsPage = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Settings' parent='Pages' title='Sample Card' />
      <Container fluid={true}>

      </Container>
    </Fragment>
  );
};

export default SettingsPage;
