import React, { useEffect, useState } from 'react';
import { LogIn, User } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import man from '../../../assets/images/dashboard/profile.png';

import { Bounce, toast } from 'react-toastify';
import { Image, LI, P, UL } from '../../../AbstractElements';
import axios from '../../../Utils/axios';
import { UserDataApi } from '../../../api';
import { Account, LogOut, Operator } from '../../../constants';

const UserHeader = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');



  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(UserDataApi);

        console.log(response)

        setProfile(response.data["logo"] || man);
        setName(response.data['name'] || '');

      } catch (error) {
        toast.error(error.response.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    }

    fetchUserData();

  }, []);

  const Logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    history(`${process.env.PUBLIC_URL}/login`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li className='profile-nav onhover-dropdown pe-0 py-0'>
      <div className='media profile-media'>
        <Image
          attrImage={{
            className: 'b-r-10 m-0',
            src: `${profile}`,
            alt: '',
          }}
        />
        <div className='media-body'>
          <span>{name}</span>
          <P attrPara={{ className: 'mb-0 font-roboto' }}>
            {Operator} <i className='middle fa fa-angle-down'></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/dashboard/settings`),
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        {/* <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app`),
          }}>
          <Mail />
          <span>{Inbox}</span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo`),
          }}>
          <FileText />
          <span>{Taskboard}</span>
        </LI> */}
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
