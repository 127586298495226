// //Samp lePage
import StationsPage from '../Components/Pages/PageLayout/StationsPage';
import DriversPage from '../Components/Pages/PageLayout/DriversPage';
import TransactionsPage from '../Components/Pages/PageLayout/TransactionsPage';
import SettingsPage from '../Components/Pages/PageLayout/SettingsPage';
import StationDetailPage from '../Components/Pages/PageLayout/StationDetailPage';

export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard/stations`, Component: <StationsPage /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/stations/details/:cp_id`, Component: <StationDetailPage /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/drivers`, Component: <DriversPage /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/transactions`, Component: <TransactionsPage /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/settings`, Component: <SettingsPage /> },
];
