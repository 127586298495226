import CreatePwd from '../Components/Pages/Auth/CreatePwd';
import ForgetPwd from '../Components/Pages/Auth/ForgetPwd';
import LoginOne from '../Components/Pages/Auth/LoginOne';
import Maintenance from '../Components/Pages/Auth/Maintenance';
import RegisterBgImg from '../Components/Pages/Auth/RegisterBgImg';
import UnlockUser from '../Components/Pages/Auth/UnlockUser';
import ErrorPage1 from '../Components/Pages/ErrorPages/ErrorPage400';
import ErrorPage2 from '../Components/Pages/ErrorPages/ErrorPage401';
import ErrorPage3 from '../Components/Pages/ErrorPages/ErrorPage403';
import ErrorPage4 from '../Components/Pages/ErrorPages/ErrorPage404';
import Error500 from '../Components/Pages/ErrorPages/ErrorPage500';
import Error503 from '../Components/Pages/ErrorPages/ErrorPage503';

export const authRoutes = [
  { path: `${process.env.PUBLIC_URL}/auth/login`, Component: <LoginOne /> },
  // { path: `${process.env.PUBLIC_URL}/auth/login-img/`, Component: <LoginOne /> },
  // { path: `${process.env.PUBLIC_URL}/auth/login-bg-img/`, Component: <LoginTwo /> },
  { path: `${process.env.PUBLIC_URL}/auth/register/`, Component: <RegisterBgImg /> },
  { path: `${process.env.PUBLIC_URL}/auth/unlock-user/`, Component: <UnlockUser /> },
  { path: `${process.env.PUBLIC_URL}/auth/forget-pwd/`, Component: <ForgetPwd /> },
  { path: `${process.env.PUBLIC_URL}/auth/create-pwd/`, Component: <CreatePwd /> },
  { path: `${process.env.PUBLIC_URL}/auth/maintenance/`, Component: <Maintenance /> },

  //Error
  { path: `${process.env.PUBLIC_URL}/errors/400/`, Component: <ErrorPage1 /> },
  { path: `${process.env.PUBLIC_URL}/errors/401/`, Component: <ErrorPage2 /> },
  { path: `${process.env.PUBLIC_URL}/errors/403/`, Component: <ErrorPage3 /> },
  { path: `${process.env.PUBLIC_URL}/errors/404/`, Component: <ErrorPage4 /> },
  { path: `${process.env.PUBLIC_URL}/errors/500/`, Component: <Error500 /> },
  { path: `${process.env.PUBLIC_URL}/errors/503/`, Component: <Error503 /> },
];
