import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import DriversTableProvider from '../../../_helper/Table/DriversTable';

const DriversPage = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <DriversTableProvider>

        </DriversTableProvider>
      </Container>
    </Fragment>
  );
};

export default DriversPage;
