import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import TransactionTableProvider from '../../../_helper/Table/TransactionsTable';

const TransactionsPage = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <TransactionTableProvider>
        </TransactionTableProvider>
      </Container>
    </Fragment >
  );
};

export default TransactionsPage;
