export const MENUITEMS = [
  {
    menutitle: 'General',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Stations',
        icon: 'others',
        type: 'link',
        path: `${process.env.PUBLIC_URL}/dashboard/stations`,
      },
      {
        title: 'Drivers',
        icon: 'user',
        type: 'link',
        path: `${process.env.PUBLIC_URL}/dashboard/drivers`,
      },
      {
        title: 'Transactions',
        icon: 'sample-page',
        type: 'link',
        path: `${process.env.PUBLIC_URL}/dashboard/transactions`,
      },
      {
        title: 'Settings',
        icon: 'editors',
        type: 'link',
        path: `${process.env.PUBLIC_URL}/dashboard/settings`,
      }
    ],
  },
];
