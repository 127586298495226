import { AlertTriangle, Circle } from "react-feather";

export const CPStatusOnline = "Available";
export const CPStatusOflline = "Unavailable";
export const CPStatusNoSignal = "Faulted";

export const CPStatusToBgIconMap = {
  [CPStatusOnline]: {
    class: "bg-light-success font-success",
    icon: <Circle />,
  },
  [CPStatusOflline]: {
    class: "bg-light-danger font-danger",
    icon: <AlertTriangle />,
  },
  [CPStatusNoSignal]: {
    class: "bg-light-primary font-primary",
    icon: <AlertTriangle />,
  },
};
