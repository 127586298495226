import axios from 'axios';
import { BASE_URL, RefreshTokenApi } from '../api';

const refreshToken = async () => {
    const response = await put(RefreshTokenApi, { refresh_token: localStorage.getItem("refresh_token") }, undefined, false);

    if (response.status === 200) {
        localStorage.setItem('access_token', response.data['access_token']);
        localStorage.setItem('refresh_token', response.data['refresh_token']);
    }
    else if (response.status === 401) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    }
}

const post = async (path = "", data = {}, headers = {}, apiHeader = true) => {
    if (apiHeader) {
        headers["Authorization"] = `Bearer ${localStorage.getItem('access_token')}`;
        headers['Content-Type'] = 'application/json';
    }
    const response = await axios.post(`${BASE_URL}${path}`, data, {
        headers: headers
    });

    if (response.status === 401)
        await refreshToken();

    return response;
};

const put = async (path = "", data = {}, headers = {}, apiHeader = true) => {
    if (apiHeader) {
        headers["Authorization"] = `Bearer ${localStorage.getItem('access_token')}`;
        headers['Content-Type'] = 'application/json';
    }
    const response = await axios.post(`${BASE_URL}${path}`, data, {
        headers: headers
    });

    if (response.status === 401)
        await refreshToken();

    return response;
}

const get = async (path = "", params = {}, headers = {}, auth = true) => {

    if (auth)
        headers["Authorization"] = `Bearer ${localStorage.getItem('access_token')}`;

    const response = await axios.get(`${BASE_URL}${path}`, {
        headers: headers,
        params: params
    });

    if (response.status === 403)
        await refreshToken();

    return response;
};

const exportedObject = {
    post,
    put,
    get
};

export default exportedObject;
