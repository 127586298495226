export const BASE_URL = `${process.env.REACT_APP_BACKEND_API_URL}`;

export const LoginApi = '/auth/operator/tokens'
export const RefreshTokenApi = '/auth/'
export const RegisterApi = '/auth/operator/register'
export const UserDataApi = '/auth/operator/me'

export const StationsListApi = '/admin/stations';
export const StationDataApi = '/admin/stations/{cp_id}/connectors';
export const StationConfigApi = '/admin/stations/{cp_id}/config';
export const TransactionListApi = '/admin/transactions';
export const DriversListApi = '/admin/drivers';
