import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { Card, CardHeader, Col, Table } from "reactstrap";
import { H3 } from "../../AbstractElements";
import { CPStatusToBgIconMap } from "../../Constants/CPStatus";
import axios from '../../Utils/axios';
import { StationsListApi } from "../../api";
import { Id, Status } from "../../constants";

const StationsTableProvider = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [setData]);

  const fetchData = async () => {
    try {
      await axios.get(StationsListApi).then((resp) => {
        setData(resp.data);
      });
    } catch (error) {
      toast.error(error.response.data.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setData([]);
    }
  };

  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardHeader>
            <H3>Stations</H3>
          </CardHeader>
          <div className="table-responsive">
            <Table hover={true} className="table-border-horizontal">
              <thead>
                <tr>
                  <th scope="col">{Id}</th>
                  <th scope="col">{Status}</th>
                  <th scope="col">Location</th>
                  <th scope="col">Last Activity</th>
                </tr>
              </thead>
              <tbody>
                {data.map((cp) => (
                  <tr
                    key={cp.id}
                    onClick={() => navigate(`details/${cp.cp_id}`)}
                  >
                    <th scope="row">{cp.cp_id}</th>
                    <td className="d-flex align-cps-center">
                      <span
                        className={`${CPStatusToBgIconMap[cp.status]["class"]
                          } rounded-1 p-1 me-2 d-flex align-cps-center`}
                      >
                        {CPStatusToBgIconMap[cp.status]["icon"]}
                      </span>
                      {cp.status}
                    </td>
                    <td>{cp.location.name}</td>
                    <td>{cp.last_activity}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default StationsTableProvider;
