import React from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Label } from "reactstrap";
import { Btn } from "../../AbstractElements";
import CommonModal from "../../Components/Common/modal";

const DeviceConfigModalForm = (props) => {
  const {
    register,
    // handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div>
      <CommonModal
        size="xl"
        isOpen={props.isOpen}
        title="Settings"
        toggler={props.toggle}
      >
        <Form className="form-bookmark needs-validation" onSubmit={() => { }}>
          <div className="form-row">
            <FormGroup className="col-md-12">
              <Label>WebUrl</Label>
              <input
                className="form-control"
                name="url"
                type="text"
                defaultValue="{editrow.website_url}"
                autoComplete="off"
                {...register("url", { required: true })}
              />
              <span style={{ color: "red" }}>
                {errors.url && "Url is required"}
              </span>
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label>Limit (kWh)</Label>
              <input
                className="form-control"
                name="title"
                type="text"
                defaultValue="{editrow.title}"
                autoComplete="off"
                {...register("title", { required: true })}
              />
              <span style={{ color: "red" }}>
                {errors.title && "Title is required"}
              </span>
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label>Description</Label>
              <input
                className="form-control"
                name="desc"
                type="textarea"
                defaultValue="{editrow.desc}"
                autoComplete="off"
                {...register("desc", { required: true })}
              />
              <span style={{ color: "red" }}>
                {" "}
                {errors.desc && "Description is required"}
              </span>
            </FormGroup>
            {/* <Row>
              <FormGroup className="col-md-6 mb-0">
                <Label>Group</Label>
                <select className="form-control digits" name="group">
                  <option value="bookmark">MyBookmarks</option>
                </select>
              </FormGroup>
              <FormGroup className="col-md-6 mb-0">
                <Label>Collection</Label>
                <select className=" form-control digits" name="collection">
                  <option value="general">General</option>
                  <option value="fs">{"fs"}</option>
                </select>
              </FormGroup>
            </Row> */}
          </div>
          <Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
          &nbsp;&nbsp;
          <Btn attrBtn={{ color: "secondary", onClick: props.toggle }}>
            Cancel{" "}
          </Btn>
        </Form>
      </CommonModal>
    </div>
  );
};

export default DeviceConfigModalForm;

/*
  const [formData, setFormData] = useState({
    pileNo: "",
    chargingCurrent: "",
    protocolVersion: "",
    plugInGunCharging: false,
    serverUrl: "",
    ocppAuthPass: "",
  });

  const handleChange = (event) => {
    const { name, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Handle form submission logic here
    // (e.g., send configuration data to an API, update local state)

    console.log("Form submitted:", formData);
    onClose(); // Close the pop-up after submission
  };

  return (
    <Modal
      isOpen={true} // Make sure to set isOpen to true for initial rendering
      contentLabel="Device Configuration"
      onClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          background: "#fff",
          padding: "20px",
          border: "1px solid #ddd",
          borderRadius: "5px",
          width: "600px", // Adjust width as needed
        },
      }}
    >
      <h2>ELCAR Pile Setting</h2>
      <p>
        * Please consult the salesman before modifying the page data, otherwise
        the charging point may work abnormally.
      </p>
      <form onSubmit={handleSubmit}>
        {// Form fields for configuration }
        <label htmlFor="pileNo">Pile No:</label>
        <input
          type="text"
          id="pileNo"
          name="pileNo"
          value={formData.pileNo}
          onChange={handleChange}
        />
        <label htmlFor="chargingCurrent">Charging Current:</label>
        <input
          type="text"
          id="chargingCurrent"
          name="chargingCurrent"
          value={formData.chargingCurrent}
          onChange={handleChange}
        />
        <label htmlFor="protocolVersion">Protocol Version:</label>
        <input
          type="text"
          id="protocolVersion"
          name="protocolVersion"
          value={formData.protocolVersion}
          onChange={handleChange}
        />
        <label htmlFor="plugInGunCharging">Plug in gun charging:</label>
        <input
          type="checkbox"
          id="plugInGunCharging"
          name="plugInGunCharging"
          checked={formData.plugInGunCharging}
          onChange={handleChange}
        />
        <label htmlFor="serverUrl">Server Url:</label>
        <input
          type="text"
          id="serverUrl"
          name="serverUrl"
          value={formData.serverUrl}
          onChange={handleChange}
        />
        <label htmlFor="ocppAuthPass">OCPP Auth Pass:</label>
        <input
          type="text"
          id="ocppAuthPass"
          name="ocppAuthPass"
          value={formData.ocppAuthPass}
          onChange={handleChange}
        />
        <button type="submit">Submit</button>
      </form>
    </Modal>
  );
*/
