import React, { Fragment, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Lock } from "react-feather";
import { Link, useParams } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { Button, Card, CardHeader, Col, Table } from "reactstrap";
import { H3 } from "../../AbstractElements";
import {
  ConnStateToIconMap,
  ConnStatusToBgClassMap
} from "../../Constants/ConnectorStatus";
import { TransactionsPageURL } from "../../Constants/Misc";
import axios from '../../Utils/axios';
import { StationConfigApi, StationDataApi, UserDataApi } from "../../api";
import { Status } from "../../constants";
import DeviceConfigModalForm from "../Modal/StationConfigModal";

const StationDetailTable = () => {
  // CHANGE CONNECTOR ICON AND POST

  const unlockConnector = (id) => {
    document.getElementById("state" + id).innerHTML =
      ReactDOMServer.renderToString(<Lock />);
  };

  // GET DATA
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchUserData();
    fetchStationData();
  }, []);

  const fetchUserData = async () => {

    if (localStorage.getItem('Name'))
      return;

    try {
      const response = await axios.get(UserDataApi);
      localStorage.setItem('Name', response.data['name']);
    } catch (error) {
      toast.error(error.response.data.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const fetchStationData = async () => {
    try {
      await axios.get(StationDataApi.replace("{cp_id}", cp_id)).then((resp) => {
        setData(resp.data);
      });
    } catch (error) {
      toast.error(error.response.data.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setData([]);
    }
  };

  // MODAL
  const [configModal, setConfigModal] = useState(false);
  const toggleConfigModal = () => {
    setConfigModal(!configModal);
    console.log("toggleConfigModal");
    console.log("Current state: " + configModal);
    !configModal && fetchConfigData();
  };

  // GET DATA
  const [configData, setConfigData] = useState([]);

  const fetchConfigData = async () => {
    console.log("fetchConfigData");

    try {
      await axios
        .get(StationConfigApi.replace("{cp_id}", cp_id), {
          headers: { 'Authorization': `Bearer ${data['access_token']}` },
        })
        .then((resp) => {
          setConfigData(resp.data);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      setConfigData([]);
    }
  };

  const { cp_id } = useParams();
  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardHeader>
            <H3>Station {cp_id}</H3>
            <div>
              <Button color="primary" size="sm" onClick={toggleConfigModal}>
                Configure
              </Button>

              <Link to={`${TransactionsPageURL}?cp_id=${cp_id}`}>
                <Button color="secondary" size="sm" className="ms-2">
                  Transactions
                </Button>
              </Link>
              <Button color="success" size="sm" className="ms-2">
                Logs
              </Button>
            </div>
          </CardHeader>
          <DeviceConfigModalForm
            toggle={toggleConfigModal}
            isOpen={configModal}
            data={configData}
          />
          <div className="table-responsive">
            <Table hover={true} className="table-border-horizontal">
              <thead>
                <tr>
                  <th scope="col">Connector</th>
                  <th scope="col">{Status}</th>
                  <th scope="col">Max Limit</th>
                  <th scope="col">Price Rate</th>
                  <th scope="col">Connector Type</th>
                  <th scope="col">Locked/Unlocked</th>
                </tr>
              </thead>
              <tbody>
                {data.map((conn) => (
                  <tr key={conn.id}>
                    <th scope="row">{conn.id}</th>
                    <td>{conn.status + (conn.status == 'Faulted' ? (' (' + conn.error_code + ')') : '')}</td>
                    <td>{conn.max_kw} kW</td>
                    <td>{conn.cp.rate} kW/h</td>
                    <td> {conn.plug} </td>
                    <td
                    // onClick={
                    //   conn.status === ConnectorStatusCharging
                    //     ? null
                    //     : () => unlockConnector(conn.id)
                    // }
                    >
                      <span
                        id={"state" + conn.id}
                        className={`${ConnStatusToBgClassMap[conn.status]
                          } rounded-1 p-1 me-2 d-flex align-items-center`}
                      >
                        {ConnStateToIconMap[conn.state]}
                      </span>
                      { }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default StationDetailTable;
