import React, { Fragment, useEffect, useState } from "react";
import { Bounce, toast } from "react-toastify";
import { Card, CardHeader, Col, Table } from "reactstrap";
import { H3 } from "../../AbstractElements";
import axios from '../../Utils/axios';
import { TransactionListApi } from "../../api";

const TransactionsTableProvider = () => {
  // GET DATA
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [setData]);

  const fetchData = async () => {
    // Get all query params from URL
    const queryParams = new URLSearchParams(window.location.search);
    try {
      await axios.get(TransactionListApi, queryParams).then((resp) => {
        setData(resp.data);
      });
    } catch (error) {
      toast.error(error.response.data.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setData([]);
    }
  };

  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardHeader>
            <H3>Transactions</H3>
          </CardHeader>
          <div className="table-responsive">
            <Table hover={true} className="table-border-horizontal">
              <thead>
                <tr>
                  <th scope="col">Station</th>
                  <th scope="col">Connector</th>
                  <th scope="col">Reserved At</th>
                  <th scope="col">Started At</th>
                  <th scope="col">Reserved Till</th>
                  <th scope="col">Finished At</th>
                  <th scope="col">Connector Limit (kWh)</th>
                  <th scope="col">Consumed (kWh)</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.cp_id}>
                    <th scope="row">{item.cp_id}</th>
                    <td>{item.conn_id}</td>
                    <td>{item.date_from}</td>
                    <td>{item.started_at}</td>
                    <td>{item.date_to}</td>
                    <td>{item.finished_at}</td>
                    <td>{item.max_kw}</td>
                    <td>{(item.meter_stop - item.meter_start).toFixed(2)}</td>
                    <td>{item.status}</td>
                    <td className="d-flex align-items-center">
                      {item.status === 'Charging' && (
                        <i className="fa fa-ban"></i>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default TransactionsTableProvider;
