import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { Card, CardHeader, Col, Table } from "reactstrap";
import { H3 } from "../../AbstractElements";
import axios from '../../Utils/axios';
import { DriversListApi } from "../../api";

const DriversTableProvider = () => {
  const navigate = useNavigate();

  // GET DATA
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [setData]);

  const fetchData = async () => {
    try {
      await axios.get(DriversListApi).then((resp) => {
        setData(resp.data);
      });
    } catch (error) {
      toast.error(error.response.data.detail, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setData([]);
    }
  };

  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardHeader>
            <H3>Drivers</H3>
          </CardHeader>
          <div className="table-responsive">
            <Table hover={true} className="table-border-horizontal">
              <thead>
                <tr>
                  <th scope="col">Full Name</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Car Brand</th>
                  <th scope="col">Num Plate</th>
                  <th scope="col">Transactions</th>
                  <th scope="col">Joined</th>
                </tr>
              </thead>
              <tbody>
                {data.map((cpdriver) => (
                  <tr key={cpdriver.driver.user.name}>
                    <th scope="row">{cpdriver.driver.user.name}</th>
                    <td>{cpdriver.driver.user.phone}</td>
                    <td>{cpdriver.driver.car_model}</td>
                    <td>{cpdriver.driver.license_plate}</td>
                    <td
                      onClick={() =>
                        navigate(`../transactions?driver_id=${cpdriver.driver.user.identifier}`, {
                          relative: "path",
                        })
                      }
                    >
                      Show
                    </td>
                    <td>{cpdriver.created_at}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default DriversTableProvider;
