import React, { Fragment } from "react";
import { Container } from "reactstrap";
import StationsTableProvider from "../../../_helper/Table/StationsTable";

const StationsPage = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        {/* <DataFetcher /> */}
        <StationsTableProvider></StationsTableProvider>
      </Container>
    </Fragment>
  );
};

export default StationsPage;
