import React from 'react';
import { Link } from 'react-router-dom';
import { DefaultPage } from '../../Constants/Misc';
import logoIcon from "../../assets/images/logo/logo-icon.png";

const SidebarIcon = () => {
  return (
    <div className="logo-icon-wrapper">
      <Link to={DefaultPage}>
        <img
          className="img-fluid"
          src={logoIcon}
          alt=""
        />
      </Link>
    </div>
  );
};

export default SidebarIcon;