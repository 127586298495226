import React, { useEffect, useState } from 'react';
import ConfigDB from '../../Config/ThemeConfig';
import { classes } from '../../Data/Layouts';
import Context from './index';

const CustomizerProvider = (props) => {
  const customizer = ConfigDB.data;
  const [layout, setLayout] = useState('');
  const [sidebarIconType, setSidebarIconType] = useState('');
  const [toggleIcon, setToggleIcon] = useState(false);
  const [mixLayout, setMixLayout] = useState(false);
  const [sidebarResponsive, setSidebarResponsive] = useState(false);
  const [IsOpen, setIsClose] = useState(false);
  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === 'compact-wrapper');
  const layoutURL = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
  const layoutValue = Object.values(defaultLayoutObj).pop();
  const location = window.location.pathname.split('/').pop();

  useEffect(() => {
    setLayout(layoutValue);
    setSidebarIconType(ConfigDB.data.settings.sidebar.iconType);
    classes.map((item) => {
      const item1 = Object.keys(item).pop();
      if (location === item1) {
        const val = Object.values(item).pop();
        ConfigDB.data.settings.sidebar.type = val;
        setLayout(val);
      }

      return null;
    });
    ConfigDB.data.settings.sidebar_setting = 'default-sidebar';
  }, []);

  //Toggle sidebar
  const toggleSidebar = (toggle) => {
    setToggleIcon(toggle);
  };

  const toggleSidebarResponsive = (toggle) => {
    setSidebarResponsive(toggle);
  };

  return (
    <Context.Provider
      value={{
        ...props,
        layout,
        sidebarIconType,
        toggleIcon,
        mixLayout,
        sidebarResponsive,
        IsOpen,
        layoutURL,
        customizer,
        setIsClose,
        setToggleIcon,
        toggleSidebarResponsive,
        setMixLayout,
        toggleSidebar: toggleSidebar,
      }}>
      {props.children}
    </Context.Provider>
  );
};

export default CustomizerProvider;
