import React, { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginOne from "../Components/Pages/Auth/LoginOne";
import { DefaultPage } from "../Constants/Misc";
import Loader from "../Layout/Loader";
import LayoutRoutes from "../Route/LayoutRoutes";
import { authRoutes } from "./AuthRoutes";
import PrivateRoute from "./PrivateRoute";


const Routers = () => {
  const jwt_token = localStorage.getItem("access_token");

  return (
    <BrowserRouter basename={"/"}>
      <>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={"/"} element={<PrivateRoute />}>
              {jwt_token ? (
                <>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}`}
                    element={<Navigate to={DefaultPage} />}
                  />
                  <Route
                    exact
                    path={`/`}
                    element={<Navigate to={DefaultPage} />}
                  />
                </>
              ) : (
                ""
              )}
              <Route path={`/*`} element={<LayoutRoutes />} />
            </Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              element={<LoginOne />}
            />
            {authRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}
          </Routes>
        </Suspense>
      </>
    </BrowserRouter>
  );
};

export default Routers;
