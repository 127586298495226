import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import StationDetailTable from '../../../_helper/Table/StationDetailTable';

const StationDetailPage = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <StationDetailTable>
        </StationDetailTable>
      </Container>
    </Fragment>
  );
};

export default StationDetailPage;
